import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Hub } from 'aws-amplify/utils';
import { AmplifyApiGWService } from './amplify-apigw.service';
import {
  getCurrentUser,
  signOut,
  fetchUserAttributes,
  updatePassword,
  updateUserAttributes,
} from 'aws-amplify/auth';
import { UserInfo } from '../interfaces';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private path = '/catalogs/userinfo';

  private authenticated = false;
  private authenticationSubject = new BehaviorSubject<boolean>(
    this.authenticated
  );

  private userDispItems = {
    initials: '',
    displayName: '',
  };
  private userDispItemsSubject = new BehaviorSubject<any>(this.userDispItems);
  public userDispItems$ = this.userDispItemsSubject.asObservable();

  userInfo: UserInfo = {} as UserInfo;
  private userInfoSubject = new BehaviorSubject<UserInfo>(this.userInfo);
  public userInfo$ = this.userInfoSubject.asObservable();

  showDashboard: boolean = false;
  private showDashboardSubject = new BehaviorSubject<boolean>(false);

  showPricingPrograms: boolean = false;
  private showPricingProgramsSubject = new BehaviorSubject<boolean>(false);

  showCostStatus: boolean = false;
  private showCostStatusSubject = new BehaviorSubject<boolean>(false);

  showAllCustomers: boolean = false;
  private showAllCustomersSubject = new BehaviorSubject<boolean>(false);

  showInsOption: boolean = false;
  private showInsOptionSubject = new BehaviorSubject<boolean>(true);

  constructor(private router: Router, private api: AmplifyApiGWService) {
    this.initialize();
  }

  private initialize(): void {
    this.checkAuthStatus();

    // Listen for auth events
    Hub.listen('auth', ({ payload }) => {
      const event = payload.event;
      console.log('Auth Event:', event);
      switch (event) {
        case 'signedIn':
          this.onSignIn();
          break;
        case 'signedOut':
          this.onSignOut();
          break;
        case 'tokenRefresh':
          console.log('Token refreshed');
          break;
      }
    });
  }

  private async checkAuthStatus(): Promise<void> {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      this.authenticated = true;
      this.authenticationSubject.next(this.authenticated);
      this.fetchUserInfo();
    } catch {
      this.authenticated = false;
      this.authenticationSubject.next(this.authenticated);
      this.showDashboard = false;
      this.showDashboardSubject.next(this.showDashboard);
    }
  }

  private async onSignIn(): Promise<void> {
    try {
      this.authenticated = true;
      this.authenticationSubject.next(this.authenticated);
      this.router.navigate(['/index_home']);
      this.fetchUserInfo();
    } catch (error) {
      console.error('Error after sign-in:', error);
    }
  }

  private onSignOut(): void {
    this.authenticated = false;
    this.authenticationSubject.next(this.authenticated);
    this.userDispItems = {
      initials: '',
      displayName: '',
    };
    this.userDispItemsSubject.next(this.userDispItems);
    this.clearSessionInfo();
    this.router.navigate(['/index_home']);
  }

  private async fetchUserInfo() {
    try {
      const path = `${this.path}`;
      const { username, userId, signInDetails } = await getCurrentUser();
      const attributes = await fetchUserAttributes();
      const givenName = attributes.given_name || '';
      const familyName = attributes.family_name || '';
      const full_name = `${givenName} ${familyName}`.trim();
      const userInitials =
        `${(givenName.charAt(0) || '').toUpperCase()}${(
          familyName.charAt(0) || ''
        ).toUpperCase()}` || username.charAt(0).toUpperCase();
      this.userDispItems = {
        initials: userInitials,
        displayName: username,
      };
      this.userDispItemsSubject.next(this.userDispItems);

      const encodedUsername = username
        ? username.replace(/@/g, '_at_').replace(/\./g, '_dot_')
        : '';
      const queryParams = { username: encodedUsername };

      const response = await this.api.callApi(path, 'GET', queryParams);

      // console.log('User info response:', response);

      const groupInfo = response?.group || {};
      const companyList = groupInfo.companies_list
        ? JSON.parse(groupInfo.companies_list)
        : [];

      this.userInfo = {
        full_name: full_name,
        first_name: givenName,
        last_name: familyName,
        username: username,
        email: attributes.email || '',
        phone_number: attributes.phone_number || '',
        company: companyList[0] || null,
        pricing_program: groupInfo['pricing_program'] || null,
        companyList: companyList.length > 0 ? companyList : [],
        parent_company:
          groupInfo['parent_company'] &&
          groupInfo['parent_company'].toLowerCase() !== 'none'
            ? groupInfo['parent_company']
            : null,
        support_email:
          groupInfo['support_email'] &&
          groupInfo['support_email'].toLowerCase() !== 'none'
            ? groupInfo['support_email']
            : 'freight@freightdesk.ai',
        email_cc_list:
          groupInfo['email_cc_list'] &&
          groupInfo['email_cc_list'].toLowerCase() !== 'none'
            ? JSON.parse(groupInfo['email_cc_list'])
            : null,
        permissionsList: [],
      };

      if (response?.group) {
        [
          'dashboards',
          'pricing_programs',
          'costs',
          'customers',
          'insurance',
          'super_user',
        ].forEach((permission) => {
          if (groupInfo[permission]) {
            this.userInfo.permissionsList.push(
              permission.charAt(0).toUpperCase() + permission.slice(1)
            );
          }
        });
      } else {
        this.userInfo.permissionsList = ['Insurance'];
      }

      this.setShowDashboard(groupInfo['dashboards'] || false);
      this.setShowPricingPrograms(groupInfo['pricing_programs'] || false);
      this.setShowCostStatus(groupInfo['costs'] || false);
      this.setShowAllCustomers(groupInfo['customers'] || false);
      this.setShowInsOption(groupInfo['insurance'] || true);

      console.log('User info for userInfoSubject:', this.userInfo);

      this.userInfoSubject.next(this.userInfo);
    } catch (error) {
      console.error('Failed to fetch user info:', error);
      this.userInfoSubject.next({} as UserInfo);
      this.userDispItems = {
        initials: '',
        displayName: '',
      };
      this.userDispItemsSubject.next(this.userDispItems);
    }
  }

  async logout(): Promise<void> {
    try {
      await signOut();
      this.clearUserDetails();
    } catch (error) {
      console.error('Logout error:', error);
    }
  }

  async changePassword(
    lastPass: string,
    newPass: string,
    answerFun: (error: boolean, message: string) => void
  ) {
    try {
      const user = await getCurrentUser();
      await updatePassword({ oldPassword: lastPass, newPassword: newPass });
      answerFun(false, 'Password updated successfully');
    } catch (error) {
      console.error('Password change error: ', error);
      const errorMessage = (error as Error).message || JSON.stringify(error);
      answerFun(true, errorMessage);
    }
  }

  async updateName(
    firstName: string,
    lastName: string,
    answerFun: (error: boolean, message: string) => void
  ) {
    try {
      const { username, userId, signInDetails } = await getCurrentUser();
      await updateUserAttributes({
        userAttributes: { given_name: firstName, family_name: lastName },
      });
      answerFun(false, 'Name updated successfully');
      this.userInfo.first_name = firstName;
      this.userInfo.last_name = lastName;
      this.userInfo.full_name = `${firstName} ${lastName}`.trim();
      this.userInfoSubject.next(this.userInfo);
      this.userDispItemsSubject.next(this.userDispItems);
      const userInitials =
        `${(firstName.charAt(0) || '').toUpperCase()}${(
          lastName.charAt(0) || ''
        ).toUpperCase()}` || username.charAt(0).toUpperCase();
      this.userDispItems = {
        initials: userInitials,
        displayName: username,
      };
      this.userDispItemsSubject.next(this.userDispItems);
    } catch (error) {
      console.error('Name update error: ', error);
      const errorMessage = (error as Error).message || JSON.stringify(error);
      answerFun(true, errorMessage);
    }
  }

  async updateContactInfo(
    email: string,
    phone_number: string,
    answerFun: (error: boolean, message: string) => void
  ) {
    try {
      await updateUserAttributes({
        userAttributes: { email: email, phone_number: phone_number },
      });
      answerFun(false, 'Contact information updated successfully');
      this.userInfo.email = email;
      this.userInfo.phone_number = phone_number;
      this.userInfoSubject.next(this.userInfo);
    } catch (error) {
      console.error('Name update error: ', error);
      const errorMessage = (error as Error).message || JSON.stringify(error);
      answerFun(true, errorMessage);
    }
  }

  isAuthenticated(): Observable<boolean> {
    return this.authenticationSubject.asObservable();
  }

  getUserInitials(): Observable<any> {
    return this.userDispItemsSubject.asObservable();
  }

  private clearSessionInfo() {
    this.clearUserDetails();
    this.setShowDashboard(false);
    this.setShowPricingPrograms(false);
    this.setShowCostStatus(false);
    this.setShowAllCustomers(false);
    this.setShowInsOption(false);
    // console.log('Session is not valid');
  }

  clearUserDetails() {
    this.userInfo = {
      first_name: '',
      last_name: '',
      full_name: '',
      username: '',
      email: '',
      phone_number: '',
      company: '',
      pricing_program: '',
      companyList: [],
      parent_company: '',
      support_email: '',
      email_cc_list: [],
      permissionsList: [],
    };
    this.userInfoSubject.next(this.userInfo);
    // console.log('User details cleared: ', this.userDetails);
  }

  getUserDetails(): Observable<any> {
    return this.userInfoSubject.asObservable();
  }

  setShowDashboard(showDashboard: boolean) {
    if (this.showDashboard !== showDashboard) {
      this.showDashboard = showDashboard;
      this.showDashboardSubject.next(showDashboard);
    }
    // console.log('Dashboard set to: ', showDashboard);
  }

  showDash(): Observable<boolean> {
    return this.showDashboardSubject.asObservable();
  }

  setShowCostStatus(showCostStatus: boolean) {
    if (this.showCostStatus !== showCostStatus) {
      this.showCostStatus = showCostStatus;
      this.showCostStatusSubject.next(showCostStatus);
    }
    // console.log('Cost status set to: ', showCostStatus);
  }

  showCosts(): Observable<boolean> {
    return this.showCostStatusSubject.asObservable();
  }

  setShowAllCustomers(showAllCustomers: boolean) {
    if (this.showAllCustomers !== showAllCustomers) {
      this.showAllCustomers = showAllCustomers;
      this.showAllCustomersSubject.next(showAllCustomers);
    }
    // console.log('Show all customers set to: ', showAllCustomers);
  }

  showAllCust(): Observable<boolean> {
    return this.showAllCustomersSubject.asObservable();
  }

  setShowPricingPrograms(showPricingPrograms: boolean) {
    if (this.showPricingPrograms !== showPricingPrograms) {
      this.showPricingPrograms = showPricingPrograms;
      this.showPricingProgramsSubject.next(showPricingPrograms);
    }
    // console.log('Pricing programs set to: ', showPricingPrograms);
  }

  showPP(): Observable<boolean> {
    return this.showPricingProgramsSubject.asObservable();
  }

  setShowInsOption(showInsOption: boolean) {
    if (this.showInsOption !== showInsOption) {
      this.showInsOption = showInsOption;
      this.showInsOptionSubject.next(showInsOption);
    }
    // console.log('Insurance option set to: ', showInsOption);
  }

  showInsuranceOption(): Observable<boolean> {
    return this.showInsOptionSubject.asObservable();
  }
}
