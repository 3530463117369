<ion-app>
  <ion-menu contentId="main-content" side="end">
    <ion-header>
      <ion-toolbar color="dark">
        <ion-title *ngIf="!displayName">MENU</ion-title>
        <ion-title *ngIf="displayName" class="fd-menu-title"><b><i>{{ displayName }}</i></b></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item class="fd-navbar-button" (click)="handleMenuAction('/index-home')">HOME</ion-item>
        <ion-item class="fd-navbar-button" *ngIf="(authenticator.isAuthenticated() | async)"
          (click)="handleMenuAction('/app/rate-quote')">RATE
          QUOTE</ion-item>
        <ion-item class="fd-navbar-button" *ngIf="(authenticator.isAuthenticated() | async)"
          (click)="handleMenuAction('/app/shipment')">SHIPMENT</ion-item>
        <ion-item class="fd-navbar-button"
          *ngIf="(authenticator.isAuthenticated() | async) && (authenticator.showDash() | async)"
          (click)="handleMenuAction('/app/dashboard')">DASHBOARD</ion-item>
        <ion-item class="fd-navbar-button" *ngIf="(authenticator.isAuthenticated() | async)"
          (click)="handleMenuAction('/app/settings')">PROFILE
          OPTIONS</ion-item>
        <ion-item class="fd-navbar-button" (click)="handleMenuAction('/contact')">CONTACT</ion-item>
        <ion-item class="fd-navbar-button" *ngIf="(authenticator.isAuthenticated() | async)"
          (click)="handleLogout()">LOG
          OUT</ion-item>
        <ion-item class="fd-navbar-button" *ngIf="!(authenticator.isAuthenticated() | async)"
          (click)="handleMenuAction('/login')">LOG
          IN</ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-header>
    <ion-toolbar>
      <ion-title class="fd_logo">
        <ion-router-link routerLink="/index-home">
          <img src="assets/img/freightdesk_logo.svg" alt="Logo">
        </ion-router-link>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button class="fd-navbar-button fdIonShowButton" routerLink="/index-home">HOME</ion-button>
        <ion-button class="fd-navbar-button fdIonShowButton" *ngIf="(authenticator.isAuthenticated() | async)"
          routerLink="/app/rate-quote">RATE
          QUOTE</ion-button>
        <ion-button class="fd-navbar-button fdIonShowButton" *ngIf="(authenticator.isAuthenticated() | async)"
          routerLink="/app/shipment">SHIPMENT</ion-button>
        <ion-button class="fd-navbar-button fdIonShowButton"
          *ngIf="(authenticator.isAuthenticated() | async) && (authenticator.showDash() | async)"
          routerLink="/app/dashboard">DASHBOARD</ion-button>
        <ion-button class="fd-navbar-button fdIonShowButton" *ngIf="!(authenticator.isAuthenticated() | async)"
          routerLink="/contact">CONTACT</ion-button>
        <ion-button class="fd-navbar-button fdIonShowButton" *ngIf="!(authenticator.isAuthenticated() | async)"
          routerLink="/login">LOG IN</ion-button>
        <ion-button class="fd-navbar-button fdIonShowButton" *ngIf="(authenticator.isAuthenticated() | async)">
          <div class="fd-user-dropdown" (click)="toggleDropdown()">
            <div *ngIf="userInitials" class="fd-user-initials" id="user-initials">{{ userInitials }}</div>
            <div *ngIf="!userInitials" class="fd-navbar-button" id="user-initials">User<br> Options</div>
            <!-- <ion-icon name="chevron-down-outline"></ion-icon>  -->
          </div>
        </ion-button>
        <ion-menu-button class="fdIonHideMenu" autoHide="true"></ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="content-wrapper">
    <div
      *ngIf="isDropdownVisible"
      class="fd-user-dropdown fd-dropdown-menu-visible"
      (click)="$event.stopPropagation()">
      <ion-list>
        <ion-item *ngIf="displayName" class="fd-navbar-button">
          <ion-label><b><i>{{ displayName }}</i></b></ion-label>
        </ion-item>
        <ion-item class="fd-navbar-button" (click)="handleMenuAction('/app/settings')">PROFILE SETTINGS</ion-item>
        <ion-item class="fd-navbar-button" (click)="handleMenuAction('/contact')">CONTACT</ion-item>
        <ion-item class="fd-navbar-button" (click)="handleLogout()">LOG OUT</ion-item>
      </ion-list>
    </div>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-content>
  <ion-footer>
  </ion-footer>
</ion-app>