import { Injectable } from '@angular/core';
import { get, post, put, del } from '@aws-amplify/api';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AmplifyApiGWService {
  private apiName = environment.APIName;
  private apiKey = environment.ApiKey;
  responseBody: any;

  constructor() {}

  public async callApi(
    apiPath: string,
    method: string,
    queryParams: any = {},
    body: any = {},
    apiName: string = this.apiName,
  ): Promise<any> {
    // Create a function to sort query parameters alphabetically by their key.
    const sortedQueryParams = this.sortQueryParams(queryParams);

    // Create path with query parameters
    const options = this.buildOptions(method, sortedQueryParams, body);

    console.log('apiName: ', apiName);
    console.log('apiPath: ', apiPath);
    console.log('method: ', method);
    console.log('options: ', options);

    let response: any;

    switch (method) {
      case 'GET': {
        response = await this.makeApiCallGet(apiName, apiPath, method, options);
        break;
      }
      case 'POST': {
        response = await this.makeApiCallPost(apiName, apiPath, method, options);
        break;
      }
      case 'PUT': {
        response = await this.makeApiCallPut(apiName, apiPath, method, options);
        break;
      }
      case 'DELETE': {
        response = await this.makeApiCallDel(apiName, apiPath, method, options);
        break;
      }
      default: {
        throw new Error('Unsupported HTTP method');
      }
    }

    return response;
  }

  sortQueryParams (params: any): any {
    // for each key:value pair in the object, if the value is a comma-separated string, split it into an array, sort it, and join it back into a string. Make sure to deal with strings where commas have been replaced with %2C.
    Object.keys(params).forEach((key) => {
      if ((typeof params[key] === 'string') && (params[key].replace(/%2C/g, ',').includes(','))) {
        params[key] = params[key].replace(/%2C/g, ',').split(',').map(Number).sort((a: number, b: number) => a - b).join(',');
        // params[key] = params[key].replace(/%2C/g, ',').split(',')[0];
      }
    });

    return Object.keys(params)
      .sort()
      .reduce((acc: any, key: any) => {
        acc[key] = params[key];
        return acc;
      }, {});
  };

  private buildOptions(method: string, queryParams: any, body: any): any {
    const options: any = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': this.apiKey
      },
      queryParams,
    };

    if (method !== 'GET') {
      options.body = body;
    }
    return options;
  }

  private async makeApiCallGet(apiName: string, path: string, method: string, options: any): Promise<any> {
    const { body } = await get({ apiName, path, options }).response;
    const response = await body.json();

    return response;
  }

  private async makeApiCallPost(apiName: string, path: string, method: string, options: any): Promise<any> {
    const { body } = await post({ apiName, path, options }).response;
    const response = await body.json();

    return response;
  }

  private async makeApiCallPut(apiName: string, path: string, method: string, options: any): Promise<any> {
    const { body } = await put({ apiName, path, options }).response;
    const response = await body.json();

    return response;
  }

  private async makeApiCallDel(apiName: string, path: string, method: string, options: any): Promise<any> {
    const payload = await del({ apiName, path, options }).response;
    const response = await payload;

    return response;
  }

}
