import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { Amplify } from 'aws-amplify';

// Amplify.configure(environment.amplifyConfig);
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: environment.authParams.userPoolId,
      userPoolClientId: environment.authParams.userPoolClientId,
      identityPoolId: environment.authParams.identityPoolId,
      signUpVerificationMethod: "code",
      userAttributes: {
        email: {
          required: true,
        },
      },
      allowGuestAccess: false,
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
    },
  },
  API: environment.APIParams,
})

if (environment.production) {
  enableProdMode();

  console.log = function () {};
  console.warn = function () {};
} else {
  console.log('Development mode');
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));