import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard'; // Import the AuthGuard to protect routes
import { IndexHomeComponent } from './pages/index-home/index-home.component';
import { LoginModule } from './pages/login/login.module';

/**
 * Routes configuration for the application.
 * It includes both protected and unprotected routes.
 * Unprotected routes: Login and Register.
 * Protected routes: Dashboard, Settings, Shipment, and Rate-Quote are grouped under a single parent route
 * that uses the AuthGuard to ensure the user is authenticated before accessing these routes.
 */
const routes: Routes = [
  // Redirect base URL to home page
  {
    path: '',
    redirectTo: 'index-home',
    pathMatch: 'full'
  },
  // Home page route, accessible without authentication.
  {
    path: 'index-home',
    loadChildren: () => import('./pages/index-home/index-home.module').then(m => m.IndexHomeModule),
    data: { title: 'FreightDesk.ai Home Page' }
  },
  // Route for the login page, accessible without authentication.
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    data: { title: 'FreightDesk.ai Login Page' }
    // This route lazily loads the LoginModule.
  },
  // Route for the contact page, accessible without authentication.
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  // // Route for the registration page, accessible without authentication.
  // {
  //   path: 'register',
  //   loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule),
  //   // This route lazily loads the RegisterModule.
  // },
  // Base route for all protected routes, requiring user authentication.
  {
    path: 'app',
    canActivateChild: [AuthGuard], // Apply the AuthGuard to protect all child routes.
    children: [
      // Child route for the dashboard, protected by the AuthGuard.
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule),
        // This route lazily loads the DashboardModule.
      },
      // Child route for the settings, protected by the AuthGuard.
      {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule),
        // This route lazily loads the SettingsModule.
      },
      // Child route for the shipment page, protected by the AuthGuard.
      {
        path: 'shipment',
        loadChildren: () => import('./pages/shipment/shipment.module').then(m => m.ShipmentPageModule),
        // This route lazily loads the ShipmentModule.
      },
      // Child route for the rate-quote page, protected by the AuthGuard.
      {
        path: 'rate-quote',
        loadChildren: () => import('./pages/rate-quote/rate-quote.module').then(m => m.RateQuotePageModule),
        // This route lazily loads the RateQuoteModule.
      },
  //     // Additional protected child routes can be added here following the same pattern.
    ]
  },
  // // Wildcard route for handling undefined paths. Redirects to the dashboard or login page.
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  // },
  {
    path: '**',
    redirectTo: 'index-home', // Redirect unauthenticated users to the home page.
    pathMatch: 'full'
  },
];

/**
 * The AppRoutingModule class that imports and exports the RouterModule.
 * The RouterModule.forRoot(routes) method is used to register the routes configuration
 * at the application's root level, enabling lazy loading and route protection.
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule] // Export RouterModule to make it available throughout the application.
})
export class AppRoutingModule { }
