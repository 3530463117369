import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AuthenticationService } from './services/authentication.service';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'FreightDesk.ai';
  isDropdownVisible = false;
  userInitials = '';
  displayName = '';

  constructor(
    private dataService: DataService,
    public authenticator: AuthenticationService,
    private router: Router,
    private menuController: MenuController
  ) {
    this.authenticator.userDispItems$.subscribe((userDispItems) => {
      this.userInitials = userDispItems.initials;
      this.displayName = userDispItems.displayName;
    });
  }

  handleMenuAction(route: string) {
    this.isDropdownVisible = false;
    this.router.navigate([route]);
    this.menuController.close();
  }

  toggleDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  async handleLogout() {
    this.isDropdownVisible = false;
    try {
      await this.authenticator.logout(); // Ensure this method logs the user out
      this.router.navigate(['/index-home']); // Redirect to the home page or any page you prefer
      this.menuController.close(); // Close the menu
    } catch (error) {
      console.error('Error during logout:', error);
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    // Check if the clicked element is inside the dropdown or the trigger
    if (!target.closest('.fd-user-dropdown')) {
      this.isDropdownVisible = false;
    }
  }
  
  @HostListener('document:keydown.escape', ['$event'])
  onEscapePress(event: KeyboardEvent) {
    this.isDropdownVisible = false;
  }
}
