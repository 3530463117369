import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Subscription, from } from 'rxjs';
import { throwError, Observable, BehaviorSubject, of } from 'rxjs';
import { AmplifyApiGWService } from './amplify-apigw.service';
import { catchError, switchMap, map } from 'rxjs/operators';
import { contactObj, UserInfo } from '../interfaces';

/**
 * Provides a wrapper around the browser's localStorage functionality,
 * offering typed methods for setting and getting values in a variety of formats.
 */
@Injectable({ providedIn: 'root' })
export class DataService {
  private subscriptions: Subscription[] = [];
  private showAllCustomers: boolean = false;
  private superUser: boolean = false;
  private userGroups: string[] = [];
  private userCompanies: string[] = [];
  private userCompanyList: any[] = [];
  private primaryCustomer: any;
  private support_email: string = 'freight@freightdesk.ai';
  private pricing_programs: any[] = [{ name: 'DEFAULT', value: 'DEFAULT' }];
  private selectedPricingProgram: string = 'DEFAULT';
  private custIDString: string = '';
  private addressCustIDs: string = '';
  private allCustIDs: string = '';
  private userInfo: UserInfo = {} as UserInfo;
  loggedIn: boolean = false;

  private addressListSubject = new BehaviorSubject<any[]>([]);
  addressList$ = this.addressListSubject.asObservable();

  private customerListSubject = new BehaviorSubject<any[]>([]);
  customerList$ = this.customerListSubject.asObservable();

  private custIDSubject = new BehaviorSubject<string>('');
  custID$ = this.custIDSubject.asObservable();

  private parentCustIDSubject = new BehaviorSubject<string>('');
  parentCustID$ = this.parentCustIDSubject.asObservable();

  private supportEmailSubject = new BehaviorSubject<string>('');
  supportEmail$ = this.supportEmailSubject.asObservable();

  private pricingProgramSubject = new BehaviorSubject<any[]>([]);
  pricingPrograms$ = this.pricingProgramSubject.asObservable();

  private selectedPricingProgramSubject = new BehaviorSubject<string>(
    'DEFAULT'
  );

  constructor(
    private authService: AuthenticationService,
    private apiService: AmplifyApiGWService
  ) {
    console.log('Data Service Initialized');
    this.subscriptions.push(
      this.authService.getUserDetails().subscribe((userDetails) => {
        if (this.loggedIn) {
          console.log('User Details = ', userDetails);
          this.userInfo = JSON.parse(JSON.stringify(userDetails));
          this.userCompanies = userDetails.companyList;
          this.support_email = userDetails.support_email;
          this.supportEmailSubject.next(this.support_email);

          // if 'Customers' is in userInfo.permissionList, set showAllCustomers to true
          if (userDetails.permissionsList.includes('Customers')) {
            this.showAllCustomers = true;
          }

          // if 'Super_user' is in userInfo.permissionList, set superUser to true
          if (userDetails.permissionsList.includes('Super_user')) {
            this.superUser = true;
          }

          this.setSelectedPP(userDetails.pricing_program);
          this.loadCustomersAndAddresses();
        }
      }),
      this.authService.isAuthenticated().subscribe((loggedIn) => {
        if (loggedIn) {
          this.loggedIn = true;
          if (this.userCompanies && this.userCompanies.length > 0) {
            this.loadCustomersAndAddresses();
          }
        } else {
          this.loggedIn = false;
          this.userInfo = {} as UserInfo;
          this.addressListSubject.next([]);
          this.customerListSubject.next([]);
          this.custIDSubject.next('');
          this.parentCustIDSubject.next('');
          this.supportEmailSubject.next('');
          this.pricingProgramSubject.next([]);
          this.setSelectedPP('DEFAULT');
        }
      })
    );
  }

  loadCustomersAndAddresses(): void {
    from(this.apiService.callApi('/catalogs/customers', 'GET'))
      .pipe(
        switchMap((customers) => {
          // console.log('Customers = ', customers);
          let fullCustomerList = JSON.parse(JSON.stringify(customers));
          // console.log('Customers Handler Output = ', fullCustomerList);
          // Add every object in fullCustomerList where the 'name' value is in this.userCompanies (this needs to be case insensitive) to CustomerList
          this.userCompanyList = fullCustomerList.filter((obj: any) =>
            this.userCompanies
              .map((company) => company.toLowerCase())
              .includes(obj.name.toLowerCase())
          );
          console.log('User Company List = ', this.userCompanyList);

          // Set primaryCustomer to the object in CustomerList where the 'name' value is the first name in this.userCompanies
          this.primaryCustomer = this.userCompanyList.find(
            (obj) =>
              obj.name.toLowerCase() === this.userCompanies[0].toLowerCase()
          );
          console.log('Primary Customer = ', this.primaryCustomer);

          if (this.primaryCustomer) {
            this.pricing_programs = this.primaryCustomer.pricing_programs;

            // If the primary customer has pricing programs, sort them and set the default pricing program
            if (this.pricing_programs.length > 0) {
              this.pricingProgramSubject.next(
                this.sortPricingPrograms(this.pricing_programs)
              );
              this.setSelectedPP(
                this.pricing_programs.find(
                  (program) => program.name === 'DEFAULT'
                ).value
              );
            }
          }

          // Create a string of comma-separated customer IDs from the CustomerList
          this.custIDString = this.userCompanyList
            .map((obj) => obj.cust_id)
            .join(',');
          // console.log('custIDString  = ', this.custIDString);

          // Create a string of comma-separated parent customer IDs from the CustomerList. Include only unique parent customer IDs.
          let parentCustIDList = this.userCompanyList.map(
            (obj) => obj.parent_cust_id
          );
          let parentCustIDSet = new Set(parentCustIDList);
          let parentCustIDArray = Array.from(parentCustIDSet);
          let parentCustIDString = parentCustIDArray.join(',');
          // console.log('parentCustIDString = ', parentCustIDString);

          if (this.showAllCustomers) {
            if (this.superUser) {
              // If the user is a super user, they get to see data for all customers
              this.publishCustomerList(fullCustomerList);
              this.addressCustIDs = fullCustomerList
              .map((obj: any) => obj.cust_id)
              .join(',');
              // console.log('Address Customer IDs = ', this.addressCustIDs);
            } else {
              // If the user is not a super user but still sees customers, they get to see data for all customers that are in the userCompanies list as well as those companies customers
              // Let custIDList be the list of numbers in the custIDString
              let custIDList = this.custIDString.split(',').map(Number);
              // Let customerList be the list of companies in fullCustomerList where the 'cust_id' is in custIDList or the 'parent_cust_id' is in custIDList
              let customerList = fullCustomerList.filter((obj: any) =>
                custIDList.includes(obj.cust_id) ||
                custIDList.includes(obj.parent_cust_id)
              );

              this.publishCustomerList(customerList);
              this.addressCustIDs = customerList
              .map((obj: any) => obj.cust_id)
              .join(',');
              // console.log('Address Customer IDs = ', this.addressCustIDs);
            }
          } else {
            // In this case, the user can only see data for the companies they are directly associted with
            // Let custIDList be the list of numbers in the custIDString
            let custIDList = this.custIDString.split(',').map(Number);
            // let customerList be the list of companies in fullCustomerList where the 'cust_id' is in custIDList
            let customerList = fullCustomerList.filter((obj: any) =>
              custIDList.includes(obj.cust_id)
            );

            this.publishCustomerList(customerList);
            this.addressCustIDs = customerList
            .map((obj: any) => obj.cust_id)
            .join(',');
            // console.log('Address Customer IDs = ', this.addressCustIDs);
          }

          return from(
            this.apiService.callApi(
              '/catalogs/entities',
              'GET',
              { child_cust_id: this.addressCustIDs }
            )
          );
        }),
        catchError((error) => {
          console.error('Error fetching data', error);
          return of([]); // Provide a fallback or handle errors as needed
        })
      )
      .subscribe((addresses: any[]) => {
        // Convert all strings to uppercase
        addresses.forEach((item) => {
          item.name = item.name.toUpperCase();
          item.address = item.address.toUpperCase();
          item.city = item.city.toUpperCase();
          item.postal_code = item.postal_code.toUpperCase();
          item.state = item.state.toUpperCase();
        });
        addresses.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        // console.log('Addresses Handler Output = ', addresses);
        this.addressListSubject.next(addresses); // Update addresses list
      });
  }

  publishCustomerList(customers: any[]): void {
    // Sort the customers by name
    customers.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    this.customerListSubject.next(customers);
  }

  saveAddressToDatabase(
    address: contactObj,
    custIDString: string,
    parentCustIDString: string
  ): void {
    from(
      this.apiService.callApi(
        '/catalogs/entities',
        'POST',
        {},
        {
          name: address.companyname,
          address: address.address,
          address_2: address.address2 || '',
          postal_code: address.zip,
          city: address.city,
          state: address.state,
          country: 'USA',
          residential: address.isResidential === true,
          contact_name: address.contactname,
          contact_phone: address.phone,
          contact_email: address.email,
          cust_ids: custIDString,
          parent_cust_ids: parentCustIDString,
          validated: true,
        }
      )
    ).subscribe((result) => {
      // Handle the result if needed
      console.log('Add Entity result: ', result);
    });
  }

  sortPricingPrograms(pricingPrograms: any[]) {
    let sortedPrograms = JSON.parse(JSON.stringify(pricingPrograms));
    let defaultProgram = pricingPrograms.find(
      (program) => program.name === 'DEFAULT'
    );

    if (!defaultProgram) {
      defaultProgram = { name: 'DEFAULT', value: 'DEFAULT' };
    }

    // Filter out 'DEFAULT', sort the remaining programs, and then add 'DEFAULT' back at the beginning
    sortedPrograms = sortedPrograms.filter(
      (program: { name: string; value: string }) => program.name !== 'DEFAULT'
    );
    sortedPrograms.sort((a: any, b: any) => a.name.localeCompare(b.name));
    sortedPrograms.unshift(defaultProgram);
    return sortedPrograms;
  }

  setSelectedPP(SelectedPP: string) {
    if (this.selectedPricingProgram !== SelectedPP) {
      this.selectedPricingProgram = SelectedPP;
      this.selectedPricingProgramSubject.next(SelectedPP);
    }
  }

  getSelectedPP(): Observable<string> {
    return this.selectedPricingProgramSubject.asObservable();
  }
}
